import React, { useEffect } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/MainComponent/Home";
import SearchDetails from "./Components/MainComponent/SearchDetails";
import LegalNotice from "./Components/MainComponent/LegalNotice";
import TermsOfUse from "./Components/MainComponent/TermsOfUse";
import TermsOfUseLang from "./Components/MainComponent/TermsOfUse/[lang]";
import PrivacyPolicy from "./Components/MainComponent/PrivacyPolicy";
import usePageTracking from "./usePageTracking";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  const mainStyles = {
    backgroundColor: "#F9F8F7",
    minHeight: `calc(100vh - 168px)`,
    paddingBottom: "-88px",
    boxSizing: "border-box",
    "@media (max-width: 768px)": {
      minHeight: `calc(100vh - 256px)`,
    },
  };

  const handleSearchClick = (id) => {
    console.log(id);
  };

  const handleScans = (data) => {
    console.log(data);
  };

  usePageTracking();

  useEffect(() => {
    const hash = window.location.hash.substr(2); // remove '#/'
    const queryStart = hash.indexOf("?");
    const query = queryStart !== -1 ? hash.substr(queryStart + 1) : "";
    const hashParams = new URLSearchParams(query);
    const newLanguage = hashParams.get("language");
    if (newLanguage) {
      localStorage.setItem("language", newLanguage);
      i18n.changeLanguage(newLanguage);
    }
  }, []);

  return (
    <Suspense fallback="...is loading">
      <Router>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <>
                <div className="container">
                  <div>
                    <Header showSVG={false} />
                    <main
                      style={{
                        mainStyles,
                        backgroundColor: "#F9F8F7",
                      }}
                      className="main-content"
                    >
                      <Home
                        onSearchClick={handleSearchClick}
                        onScanResult={handleScans}
                      />
                    </main>
                  </div>
                  <Footer />
                </div>
              </>
            }
          />
          <Route
            path="/search/:gs1ElementStrings"
            element={
              <>
                <div className="container">
                  <Header showSVG={true} />
                  <main style={{ mainStyles, backgroundColor: "#F9F8F7" }}>
                    <SearchDetails />
                  </main>
                  <Footer />
                </div>
              </>
            }
          />
          <Route
            path="/search/01/:gtin"
            element={
              <>
                <div className="container">
                  <Header showSVG={true} />
                  <main style={{ mainStyles, backgroundColor: "#F9F8F7" }}>
                    <SearchDetails />
                  </main>
                  <Footer />
                </div>
              </>
            }
          />
          <Route
            path="/legal-notice"
            element={
              <>
                <Header />
                <main style={{ mainStyles, backgroundColor: "#F9F8F7" }}>
                  <LegalNotice />
                </main>
                <Footer />
              </>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              <>
                <Header />
                <main style={{ mainStyles, backgroundColor: "#F9F8F7" }}>
                  <TermsOfUse />
                </main>
                <Footer />
              </>
            }
          />
          <Route
            path="/terms-of-use/:lang"
            element={
              <>
                <Header />
                <main style={{ mainStyles, backgroundColor: "#F9F8F7" }}>
                  <TermsOfUseLang />
                </main>
                <Footer page="tou" />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Header />
                <main style={{ mainStyles, backgroundColor: "#F9F8F7" }}>
                  <PrivacyPolicy />
                </main>
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
