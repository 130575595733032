import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';

export const languageOptions = [
  { code: 'bg', name: 'BG - български' },
  { code: 'zh', name: 'ZH - 中文' },
  { code: 'cs', name: 'CS - Čeština', IMRContent: 'CP-467772 - 07/2024', IMRContentTOU: 'CP-467772 - 07/2024' },
  { code: 'da', name: 'DA - Dansk', IMRContent: 'EM-165152 - 09/2024'},
  { code: 'nl', name: 'NL - Nederlands', IMRContent: 'EM-162555 - 07/2024', IMRContentTOU: 'EM-162555 - 07/2024' },
  { code: 'en', name: 'EN - English' },
  { code: 'et', name: 'ET - Eesti', IMRContent: 'EM-161984 - 07/2024', IMRContentTOU: 'EM-161988 - 07/2024' },
  { code: 'fi', name: 'FI - Suomi' },
  { code: 'fr', name: 'FR - Français', IMRContent: 'EM-162555 - 07/2024' },
  { code: 'de', name: 'DE - Deutsch', IMRContent: 'EM-162555 - 11/2024', IMRContentTOU: 'EM-169108 - 10/2024' },
  { code: 'el', name: 'EL - Ελληνικά' },
  { code: 'hr', name: 'HR - Hrvatski', IMRContent: 'CP-483598 - 10/2024', IMRContentTOU: 'CP-483576 - 10/2024' },
  { code: 'hu', name: 'HU - Magyar', IMRContent: 'CP-468751 - 08/2024', IMRContentTOU: 'CP-469784 - 08/2024' },
  { code: 'is', name: 'IS - Íslenska', IMRContent: 'CP-477794 - 09/2024', IMRContentTOU: 'CP-477796 - 09/2024'  },
  { code: 'it', name: 'IT - Italiano', IMRContent: 'CP-476718 - 09/2024', IMRContentTOU: 'CP-476718 - 09/2024'  },
  { code: 'ko', name: 'KO - 한국어' },
  { code: 'lv', name: 'LV - Latviešu', IMRContent: 'EM-163624 - 08/2024', IMRContentTOU: 'EM-163625 - 08/2024' },
  { code: 'lt', name: 'LT - Lietuvių', IMRContent: 'EM-163627 - 08/2024', IMRContentTOU: 'EM-163626 - 08/2024' },
  { code: 'no', name: 'NO - Norsk', IMRContent: 'CP-473757 - 09/2024' },
  { code: 'pl', name: 'PL - Polski', IMRContent: 'CP-470559 - 09/2024', IMRContentTOU: 'CP-470559 - 09/2024' },
  { code: 'pt', name: 'PT - Português', IMRContent: 'CP-464858 - 09/2024' },
  { code: 'ro', name: 'RO - Română', IMRContent: 'CP-476379 - 09/2024', IMRContentTOU: 'CP-476379 - 09/2024' },
  { code: 'sk', name: 'SK - Slovenčina', IMRContent: 'CP-478047 - 09/2024', IMRContentTOU: 'CP-470910 - 08/2024' },
  { code: 'sl', name: 'SL - Slovenščina', IMRContent: 'EM-166547 - 09/2024', IMRContentTOU: 'EM-169688 - 11/2024' },
  { code: 'es', name: 'ES - Español', IMRContent: 'CP-476389 - 09/2024', IMRContentTOU: 'CP-476389 - 09/2024'},
  { code: 'sv', name: 'SV - Svenska', IMRContent: 'CP-474269 - 09/2024', IMRContentTOU: 'CP-474269 - 09/2024' },
  { code: 'th', name: 'TH - ไทย' },
  { code: 'tr', name: 'TR - Türkçe' },
].sort((a, b) => a.code.localeCompare(b.code));

function LanguageList() {
  const { i18n } = useTranslation();
  const userLanguage = navigator.language.split('-')[0];
  const lastLanguage = localStorage.getItem('language') || userLanguage;
  const [selectedLanguage, setSelectedLanguage] = useState(lastLanguage);

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    localStorage.setItem('language', newLanguage);
    setSelectedLanguage(newLanguage);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <Select
      id="dropdown"
      value={selectedLanguage}
      onChange={handleLanguageChange}
      className="custom-select-language"
      style={{ marginRight: '8px' }}
    >
      {languageOptions.map((language) => (
        <MenuItem key={language.code} value={language.code}>
          <Typography variant="body2">{language.name}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
}
LanguageList.propTypes = {
  customStyles: PropTypes.object,
};
LanguageList.defaultProps = {
  customStyles: {}, // Set a default empty object or any other default styles
};
export default LanguageList;
